'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';

class KindergartenVowelsGame extends React.Component {

	constructor(props) {
		super(props);
		this.state = {n1:[],index:0,game:[]};
		this.clickAction = this.clickAction.bind(this)
	}

	componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/vowels-game"
        }).done(response => {
            this.setState({
                n1: response.entity.n1
            });
        }, response => {
        });
    }

    clickAction(n) {
        scroll_img_game();
        if("aeiou".includes(n)) {
            $('#correctImg').show(500);
            $('#correctImg').hide(2000);
            document.getElementById("correctAnswerAudio").play();
            $('#vowels_'+n).show(500);

            this.state.index=this.state.index + 1;
            if(this.state.index === 5) {
                 setTimeout(function() {
                    document.getElementById("winGameAudio").play();
                    this.state.index=0;
                    $('#vowels_a').hide(500);$('#vowels_e').hide(500);$('#vowels_i').hide(500);$('#vowels_o').hide(500);$('#vowels_u').hide(500);
                 }.bind(this), 3000);
            }

        } else {
            $('#wrongImg').show(500);
            $('#wrongImg').hide(2000);
            document.getElementById("wrongAnswerAudio").play();
        }
    }

	render() {
		return (
		    <div><Helmet><title>Vowels Game - Kindergarten | School.StackTrace.In</title></Helmet>
		    <Container fluid className="card-body">

                <h5>Find Vowels In The English Alphabet ?</h5>
                <hr className="bg-warning"/>

                <div className="imageBox" style={{zIndex: 20}}>
                    <div id="img-game" className="row justify-content-center" style={{background: "white"}}>
                        <img id="vowels_a" src={"/img/nursery/english/a.png"} className="col-2 p-0" style={{display: "none"}}/>
                        <img id="vowels_e" src={"/img/nursery/english/e.png"} className="col-2 p-0" style={{display: "none"}}/>
                        <img id="vowels_i" src={"/img/nursery/english/i.png"} className="col-2 p-0" style={{display: "none"}}/>
                        <img id="vowels_o" src={"/img/nursery/english/o.png"} className="col-2 p-0" style={{display: "none"}}/>
                        <img id="vowels_u" src={"/img/nursery/english/u.png"} className="col-2 p-0" style={{display: "none"}}/>
                    </div>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAnswerAudio={true} wrongAnswerAudio={true} winGameAudio={true}/>
                </div>

                <hr className="bg-success"/>

                <div className="row justify-content-center">
                    { this.state.n1 && this.state.n1.map(n =>
                        <CONST.ImageAudio key={n} id={n} ImgSrc={"/img/nursery/english/"+n+".png"} ImgWidth="80" ImgHeight="80" AudioSrc={"/audio/nursery/english/"+n+".mp3"} ClickAction={this.clickAction}/>
                    )}
                </div>

			</Container>
			</div>
		)
	}
}

export default KindergartenVowelsGame;