'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';

class KindergartenEnglishGame extends React.Component {

	constructor(props) {
		super(props);
		this.state = {index:0,game:[], que:"", ans:"", opt:[]};
		this.gameAction = this.gameAction.bind(this)
	}

	componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/english-game"
        }).done(response => {
            this.setState({
                game: response.entity,
                que: response.entity[0].que,
                ans: response.entity[0].ans,
                opt: response.entity[0].opt
            });
        }, response => {
        });
    }

    gameAction(n) {
        scroll_img_game();
        if(n===this.state.ans) {
            $('#correctImg').show(500);
            $('#correctImg').hide(2000);
            document.getElementById("correctAnswerAudio").play();

            this.state.index=this.state.index + 1;
            if(this.state.index === this.state.game.length) {
                this.state.index=0;
            }

            var gameImg = this.state.game[this.state.index].que;
            $("#img-game0").fadeOut(1000, function () {
                $("#img-game0").attr('src', "");
                $("#img-game0").attr('src', "/img/kindergarten/english/"+gameImg+".png");
                $("#img-game0").fadeIn(2000);
            });

            setTimeout(function() {
                this.setState({
                    ans: this.state.game[this.state.index].ans,
                    opt: this.state.game[this.state.index].opt
                });
            }.bind(this), 1000);

        } else {
            $('#wrongImg').show(500);
            $('#wrongImg').hide(2000);
            document.getElementById("wrongAnswerAudio").play();
        }
    }

	render() {
		return (
		    <div><Helmet><title>English Game - Kindergarten | School.StackTrace.In</title></Helmet>
		    <Container fluid className="card-body">

                <h5>Initial letter of the object ?</h5>
                <hr className="bg-warning"/>

                <div className="imageBox" style={{zIndex: 20}}>
                    <div className="images" style={{position: "relative", left: 0, top: 0}}>
                        <img id="img-game" src="/img/app/blank.png" className="game-image border img-shadow img-responsive" style={{position: "relative", left: 0, top: 0}}/>
                        <img id="img-game0" src={this.state.que?("/img/kindergarten/english/"+this.state.que+".png"):""} className="game-image img-responsive p-2" style={{position: "absolute", left: 0, top: 0}}/>
                    </div>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAnswerAudio={true} wrongAnswerAudio={true}/>
                </div>

                <hr className="bg-success"/>
                <div className="row justify-content-center">
                    { this.state.opt && this.state.opt.map(n =>
                        <CONST.ImageAudio key={n} id={n} ImgSrc={"/img/kindergarten/english/"+n+".png"} ImgWidth="80" ImgHeight="80" GameAction={this.gameAction}/>
                    )}
                </div>

			</Container>
			</div>
		)
	}
}

export default KindergartenEnglishGame;