'use strict';

import * as CONST from "../app/constants";

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import ImageMapper from 'react-image-mapper';

class KindergartenMyBody extends React.Component {

    constructor(props) {
        super(props);
        this.clicked = this.clicked.bind(this);
        this.nextPart = this.nextPart.bind(this);
        this.setNextPart = this.setNextPart.bind(this);
        this.state = {
            bodyPart: ["head", "eye", "ear", "nose", "mouth", "hand", "stomach", "leg"], index: 0, indexPart: "head",
            width: 250, height: 500,
            map: {
                name: "body",
                areas: [
                    { name: "head", shape: "poly", coords: [49,108,45,68,58,36,102,2,163,5,189,24,220,60,213,114,190,104,157,76,117,83,74,81,69,103] },
                    { name: "eye", shape: "circle", coords: [98,121,16] },
                    { name: "eye", shape: "circle", coords: [157,121,17] },
                    { name: "ear", shape: "poly", coords: [50,121,61,109,72,115,70,132,71,148,55,140] },
                    { name: "ear", shape: "poly", coords: [188,113,203,112,208,129,201,143,188,148,188,130] },
                    { name: "nose", shape: "circle", coords: [127,135,14] },
                    { name: "mouth", shape: "poly", coords: [97,150,127,152,154,150,149,166,135,175,116,174,102,167] },
                    { name: "hand", shape: "poly", coords: [22,286,50,238,74,200,91,191,87,213,82,235,65,260,48,291,49,322,35,338,15,334,2,320,2,301] },
                    { name: "hand", shape: "poly", coords: [231,294,208,249,193,215,174,197,171,219,175,242,191,275,204,302,201,330,213,345,231,346,247,336,249,311,249,311] },
                    { name: "stomach", shape: "poly", coords: [77,282,83,243,94,195,132,192,165,200,171,245,175,274,175,295,141,304,95,300] },
                    { name: "leg", shape: "poly", coords: [73,321,97,340,120,352,116,405,118,456,114,483,103,496,83,494,64,490,77,459,75,382] },
                    { name: "leg", shape: "poly", coords: [132,351,158,341,181,321,176,391,172,451,182,488,163,496,137,495,135,452,137,391] }
                ]
            }
        };
        this.state.bodyPart = shuffleArray(this.state.bodyPart);
        this.state.indexPart = this.state.bodyPart[this.state.index];
    }

    clicked(part) {
        document.getElementById("clickAudio").play();
        setTimeout(function() {
            document.getElementById("audio_"+part).play();
        }, 500);
        document.getElementById("img_"+part).style.display = 'block';
        $('#body0').css("opacity", "0.5");
        setTimeout(function() {
            $('#body0').css("opacity", "1");
            document.getElementById("img_"+part).style.display = 'none';
        }, 2000);

        if(part === this.state.indexPart) {
            setTimeout(function() {
                $('#correctImg').show(500);
                $('#correctImg').hide(500);
                document.getElementById("correctAudio").play();
                this.nextPart();
            }.bind(this), 1000);
        } else {
            setTimeout(function() {
                $('#wrongImg').show(500);
                $('#wrongImg').hide(500);
                document.getElementById("wrongAudio").play();
            }, 1000);
        }
    }

    nextPart() {
        this.state.index=this.state.index+1;
        if(this.state.index === this.state.bodyPart.length) {
           this.state.index=0;
        }
        this.setState({
           index: this.state.index,
           indexPart: this.state.bodyPart[this.state.index]
       });
    }

    setNextPart(index) {
        this.setState({
           index: index,
           indexPart: this.state.bodyPart[index]
       });
    }


    render() {
        return (
            <div><Helmet><title>My Body - Kindergarten | School.StackTrace.In</title></Helmet>
            <Container fluid className="card-body" style={{minHeight: "700px"}}>
                <h5>Click on image to select body part.</h5>

                { this.state.bodyPart && this.state.bodyPart.map((part,index) =>
                    <span key={"key_"+part}>
                        <button id={"btn_"+part} className={this.state.indexPart===part?"m-1 border-warning btn btn-warning":"m-1 border-warning btn btn-light"} onClick={(part) => this.setNextPart(index)}> {part} </button>
                        <CONST.PageAudio id={part} src={"/audio/kindergarten/myBody/"+part+".mp3"}/>
                    </span>
                )}

                <div id="body0" style={{ position: "absolute" }}>
                    <ImageMapper src="/img/kindergarten/myBody/boyBody.png" map={this.state.map} width={this.state.width} height={this.state.height} active={false} onClick={(area) => this.clicked(area.name)}/>
                </div>

                { this.state.bodyPart && this.state.bodyPart.map(part =>
                    <img key={"img_"+part} id={"img_"+part} src={"/img/kindergarten/myBody/"+part+".png"} width={this.state.width} height={this.state.height} style={{ position: "absolute", display: "none" }}/>
                )}

                <div style={{position: "fixed", top: "10%", zIndex: "10"}}>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <div id="winImg" className="winImg" style={{display: "none"}}>
                        <img src="/img/app/win.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAudio={true} wrongAudio={true} winGameAudio={true} clickAudio={true} />
                </div>

            </Container>
            </div>
        );
    }
}

export default KindergartenMyBody;

