'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import { Card, CardBody, Container, Row } from 'reactstrap';
const { Link } = require('react-router-dom');

class NurseryHome extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
		    <div><Helmet><title>Nursery | School.StackTrace.In</title></Helmet>
                <div className="card-body">
                    <p> A preschool, also known as nursery school, pre-primary school, or play school, is an educational establishment or learning
                        space offering early childhood education to children before they begin compulsory education at primary school.
                    </p>
                    <div className="row justify-content-center">
                        <Link to="/nursery-math" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/basic-mathematics.png" width="150"/>
                        </Link>
                        <Link to="/nursery-math-game" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/mathematics-game.png" width="150"/>
                        </Link>
                        <Link to="/nursery-english" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/basic-english.png" width="150"/>
                        </Link>
                        <Link to="/nursery-english-game" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/english-game.png" width="150"/>
                        </Link>
                        <Link to="/nursery-english-writing" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/english-writing.png" width="150"/>
                        </Link>
                        <Link to="/nursery-hindi-a-ahh" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/hindi-a-ahh.png" width="150"/>
                        </Link>
                        <Link to="/nursery-hindi-k-gya" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/hindi-k-gya.png" width="150"/>
                        </Link>
                        <Link to="/nursery-shapes" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/basic-shapes.png" width="150"/>
                        </Link>
                        <Link to="/nursery-shapes-game" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/shapes-game.png" width="150"/>
                        </Link>
                        <Link to="/nursery-colors" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/basic-colors.png" width="150"/>
                        </Link>
                        <Link to="/nursery-colors-game" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/colors-game.png" width="150"/>
                        </Link>
                        <Link to="/nursery-fruits" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/fruits.png" width="150"/>
                        </Link>
                        <Link to="/nursery-vegetables" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/vegetables.png" width="150"/>
                        </Link>
                        <Link to="/nursery-animals" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/animals.png" width="150"/>
                        </Link>
                        <Link to="/nursery-separate-fruits-vegetables" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                            <img src="/img/nursery/home/separate-fruits-vegetables.png" width="150"/>
                        </Link>

                    </div>
                </div>
            </div>
		)
	}
}

export default NurseryHome;