'use strict';

import React from 'react'
import ReactDOM from 'react-dom';

class ResultAudio extends React.Component {

	constructor(props) {
		super(props);
		this.audioOnPlay = this.audioOnPlay.bind(this)
        this.audioOnEnded = this.audioOnEnded.bind(this)
	}

    audioOnPlay(){
        $("#audio-icon").fadeIn();
    }

    audioOnEnded(){
        $("#audio-icon").fadeOut();
    }

	render() {
	    const { correctAudio, wrongAudio, correctAnswerAudio, wrongAnswerAudio, winGameAudio, doneGreatAudio, audio1Src, clickAudio } = this.props;
	    return (
            <>
            { correctAudio ? <audio id="correctAudio" src="/audio/common/correct.mp3" onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}
            { wrongAudio ? <audio id="wrongAudio" src="/audio/common/wrong.mp3" onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}

            { correctAnswerAudio ? <audio id="correctAnswerAudio" src="/audio/common/correctAnswer.mp3" onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}
            { wrongAnswerAudio ? <audio id="wrongAnswerAudio" src="/audio/common/wrongAnswer.mp3" onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}
            { winGameAudio ? <audio id="winGameAudio" src="/audio/common/winGame.mp3" onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}
            { doneGreatAudio ? <audio id="doneGreatAudio" src="/audio/common/doneGreat.mp3" onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}
            { audio1Src ? <audio id="audio1" src={audio1Src} onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}
            { clickAudio ? <audio id="clickAudio" src="/audio/common/click.mp3" onPlay={this.audioOnPlay} onEnded={this.audioOnEnded}/> : ""}
            </>
		)
	}
}

export default ResultAudio;