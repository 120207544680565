'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';
import CanvasDraw from "react-canvas-draw";
import randomColor from "randomcolor";

class KindergartenMathOperation extends React.Component {

	constructor(props) {
		super(props);
		this.state = {n1:[], operations:[], index:0, operation:[], flag: false,
		    operationLevel:[], level:0, operationType:[], type:0, operationFormat:[], format:0,
            color: "blue", width: 300, height: 400, brushRadius: 5, lazyRadius: 0
        };
        this.nextOperation = this.nextOperation.bind(this);
        this.clickAction = this.clickAction.bind(this);
        this.nextPage = this.nextPage.bind(this);
	}

	componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/math-operation?level=0&type=0&format=0"
        }).done(response => {
            this.setState({
                n1: response.entity.n1,
                operations: response.entity.operations,
                operation: response.entity.operations[this.state.index].split(","),
                operationLevel: response.entity.operationLevel,
                operationType: response.entity.operationType,
                operationFormat: response.entity.operationFormat
            });
        }, response => {
        });
    }

    nextOperation() {
        this.state.index=this.state.index + 1;
        if(this.state.index === this.state.operations.length) {
            this.nextPage((this.state.page == (this.state.pages.length-1)) ? 0 : this.state.page+1);
        }

        setTimeout(() => {
            this.setState({
                operation: this.state.operations[this.state.index].split(","),
                flag: false
            })
        }, 1000);

        $("#game-id").fadeOut(1000, function () {
            $("#game-id").fadeIn(2000);
        });

    }

    clickAction(n) {
        scroll_img_game();
        if((n.split("-")[1]) === this.state.operation[3]) {
            this.state.flag=true;
            $('#correctImg').show(500);
            $('#correctImg').hide(2000);
            document.getElementById("correctAnswerAudio").play();
            this.nextOperation();
            this.setState({
                color: randomColor({luminosity: 'bright', format: 'rgb' })
            });
        } else {
            $('#wrongImg').show(500);
            $('#wrongImg').hide(2000);
            document.getElementById("wrongAnswerAudio").play();
        }
    }

    nextPage(oprStr,oprNum) {
        scroll_img_game();
        this.state.index=0;
        if(oprStr === "level") {
            this.state.level = oprNum;
        } else if(oprStr === "type") {
           this.state.type = oprNum;
        } else if(oprStr === "format") {
           this.state.format = oprNum;
        }

        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/math-operation?level="+this.state.level+"&type="+this.state.type+"&format="+this.state.format
        }).done(response => {
            this.setState({
                n1: response.entity.n1,
                operations: response.entity.operations,
                operation: response.entity.operations[this.state.index].split(","),
                operationLevel: response.entity.operationLevel,
                operationType: response.entity.operationType,
                operationFormat: response.entity.operationFormat
            });
        }, response => {
        });
    }

	render() {

		return (
		    <div><Helmet><title>Math Operation - Kindergarten | School.StackTrace.In</title></Helmet>
		    <Container fluid id="img-game" className="card-body">

                <div className="imageBox" style={{zIndex: 20}}>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAnswerAudio={true} wrongAnswerAudio={true}/>
                </div>

                <div id="img-game" className="row card border-warning text-center img-shadow" style={{zIndex: 10, fontFamily: "Arial", fontSize: "70px"}}>
                    <div id="game-id" className="row justify-content-center text-primary">
                        {this.state.operation[0] ?
                            <>{this.state.operation[1]} {this.state.operation[0]} {this.state.operation[2]} = {this.state.flag ? this.state.operation[3] : "?"}</>
                            : ""
                        }
                    </div>
                </div>

                <br/>
                <div className="row justify-content-center">
                    { this.state.n1 && this.state.n1.map(n =>
                        <CONST.ImageAudio key={n} id={n} ImgSrc={"/img/nursery/math/"+(n.split("-")[0])+".png"} ImgWidth="80" ImgHeight="80" AudioSrc={"/audio/nursery/math/"+(n.split("-")[0])+".mp3"} ClickAction={this.clickAction}/>
                    )}
                </div>
                <br/>

                <div className="d-flex justify-content-center">
                    <button className="btn btn-danger img-shadow btn-lg" onClick={() => { this.saveableCanvas.eraseAll(); }} style={{width:"100%"}}> Erase </button>
                </div>
                <Card className="border-0" style={{margin: "5px 0px"}}>
                    <CardBody className="bg-light p-0 mx-auto border img-shadow">
                        <h2 style={{textAlign: "center"}}> Writing Page </h2>
                        <CanvasDraw
                            ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                            brushColor={this.state.color}
                            brushRadius={this.state.brushRadius}
                            lazyRadius={this.state.lazyRadius}
                            canvasWidth={window.innerWidth - ((window.innerWidth*22)/100)}
                            canvasHeight={this.state.height}
                        />
                        </CardBody>
                </Card>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-danger img-shadow btn-lg" onClick={() => { this.saveableCanvas.eraseAll(); }} style={{width:"100%"}}> Erase </button>
                </div>


                <br/>
                 <div className="row card border-warning text-center img-shadow">
                    <h3 className="text-danger"><u>Page Setting</u></h3>
                    <fieldset className="row border m-2">
                        <legend className ='text-center m-0' style={{width: "70px"}}>Level</legend>
                        <div className="text-center p-1">
                            { this.state.operationLevel && this.state.operationLevel.map((n,i) =>
                                <button key={"level-"+i} id={"level-"+i} className="btn border-warning img-shadow m-2" style={{background:this.state.level == i ? "yellow" : "white"}}
                                    onClick={(e) => { this.nextPage("level",i) }} > {n}
                                </button>
                            )}
                        </div>
                    </fieldset>

                    <fieldset className="row border m-2">
                        <legend className ='text-center m-0' style={{width: "70px"}}>Type</legend>
                        <div className="text-center p-1">
                            { this.state.operationType && this.state.operationType.map((n,i) =>
                                <button key={"type-"+i} id={"type-"+i} className="btn border-warning img-shadow m-2" style={{background:this.state.type == i ? "yellow" : "white"}}
                                    onClick={(e) => { this.nextPage("type",i) }} > {n}
                                </button>
                            )}
                        </div>
                    </fieldset>

                    <fieldset className="row border m-2">
                        <legend className ='text-center m-0' style={{width: "100px"}}>Format</legend>
                        <div className="text-center p-1">
                            { this.state.operationFormat && this.state.operationFormat.map((n,i) =>
                                <button key={"format-"+i} id={"format-"+i} className="btn border-warning img-shadow m-2" style={{background:this.state.format == i ? "yellow" : "white"}}
                                    onClick={(e) => { this.nextPage("format",i) }} > {n}
                                </button>
                            )}
                        </div>
                    </fieldset>

                </div>

			</Container>
			</div>
		)
	}
}

export default KindergartenMathOperation;