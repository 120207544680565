'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
const { Link } = require('react-router-dom');

class NurseryLeftBar extends React.Component {

    constructor(props) {
		super(props);
    }

	render() {
		return (
			<div style={{width:"100%"}}>
                <Link to="/nursery" className="list-group-item list-group-item-action list-group-item-warning font-weight-bold text-danger"><h5 className="m-0"> Nursery Class </h5></Link>
                <Link to="/nursery-math" className="list-group-item list-group-item-action text-primary">Basic Mathematics</Link>
                <Link to="/nursery-math-game" className="list-group-item list-group-item-action text-primary">Mathematics Game</Link>
                <Link to="/nursery-english" className="list-group-item list-group-item-action text-primary">Basic English</Link>
                <Link to="/nursery-english-game" className="list-group-item list-group-item-action text-primary">English Game</Link>
                <Link to="/nursery-english-writing" className="list-group-item list-group-item-action text-primary">English Writing</Link>
                <Link to="/nursery-hindi-a-ahh" className="list-group-item list-group-item-action text-primary">Hindi (अ-अः)</Link>
                <Link to="/nursery-hindi-k-gya" className="list-group-item list-group-item-action text-primary">Hindi (क-ज्ञ)</Link>
                <Link to="/nursery-shapes" className="list-group-item list-group-item-action text-primary">Basic Shapes</Link>
                <Link to="/nursery-shapes-game" className="list-group-item list-group-item-action text-primary">Shapes Game</Link>
                <Link to="/nursery-colors" className="list-group-item list-group-item-action text-primary">Basic Colors</Link>
                <Link to="/nursery-colors-game" className="list-group-item list-group-item-action text-primary">Colors Game</Link>
                <Link to="/nursery-fruits" className="list-group-item list-group-item-action text-primary">Fruits Chapter</Link>
                <Link to="/nursery-vegetables" className="list-group-item list-group-item-action text-primary">Vegetables Chapter</Link>
                <Link to="/nursery-animals" className="list-group-item list-group-item-action text-primary">Animals Chapter</Link>
                <Link to="/nursery-separate-fruits-vegetables" className="list-group-item list-group-item-action text-primary">Separate Fruits Vegetables Game</Link>

            </div>
		)
	}
}

export default NurseryLeftBar;