'use strict';

import * as CONST from "../app/constants";

import React from 'react';
import ReactDOM from 'react-dom';

import { Card, CardBody, Container, Row } from 'reactstrap';

class NurseryApp extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
            <>
                <CONST.Header/>
                <Container fluid>

                    <div className="row">
                        <div className="col-lg-2 p-0">
                            <nav className="navbar navbar-expand-lg p-0" id="accordion">
                                <div className="list-group navbar-collapse collapse" id="navbarSideContent" data-parent="#accordion">
                                    <CONST.NurseryLeftBar/>
                                </div>
                            </nav>
                        </div>

                        <div className="col-lg-10 p-0">
                            <div className="card">

                                <div className="card-header p-1">
                                    <span className="float-left">
                                        <span className="navbar navbar-expand-lg p-0">
                                            <span className="navbar-toggler">
                                                <div className="dropdown">
                                                    <button type="button" className="btn btn-primary" data-toggle="dropdown">
                                                        <i className="fas fa-align-justify"></i>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-left" style={{fontSize: "1rem", padding: "0px"}}>
                                                        <CONST.NurseryLeftBar/>
                                                    </div>
                                                </div>
                                            </span>
                                        </span>
                                    </span>
                                    <h1 className="m-1 pl-2 text-danger"> {this.props.title} </h1>
                                </div>

                                {this.props.content}

                            </div>
                        </div>
                    </div>
                </Container>
            </>
		)
	}
}

export default NurseryApp;