'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';
const { Link } = require('react-router-dom');

class KindergartenHome extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
		    <div><Helmet><title>Kindergarten | School.StackTrace.In</title></Helmet>
            <div className="card-body">
                <p> Kindergarten is a preschool educational approach based on playing, singing, practical activities such as drawing,
                    and social interaction as part of the transition from home to school.
                </p>
                <div className="row justify-content-center">
                    <Link to="/kindergarten-english-game" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/english-game.png" width="150"/>
                    </Link>
                    <Link to="/kindergarten-vowels-game" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/vowels-game.png" width="150"/>
                    </Link>
                    <Link to="/kindergarten-english-word-game" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/english-word-game.png" width="150"/>
                    </Link>
                    <Link to="/kindergarten-make-english-word" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/make-english-word.png" width="150"/>
                    </Link>
                    <Link to="/kindergarten-find-word" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/find-word.png" width="150"/>
                    </Link>
                    <Link to="/kindergarten-number-name" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/number-name.png" width="150"/>
                    </Link>
                    <Link to="/kindergarten-math-operation" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/math-operation.png" width="150"/>
                    </Link>
                    <Link to="/kindergarten-my-body" className="lightbox card border-1 transform-on-hover-menu mb-0 img-shadow p-1 m-1">
                        <img src="/img/kindergarten/home/my-body.png" width="150"/>
                    </Link>
                </div>
            </div>
            </div>
		)
	}
}

export default KindergartenHome;