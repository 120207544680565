'use strict';

const React = require('react');
const ReactDOM = require('react-dom');

class Header extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
            <div className="header">
                <span className="navbar navbar-expand-lg static-top d-flex bg-secondary card-header p-0" style={{fontFamily: "'Times New Roman', Times, serif", backgroundImage: "url('/img/home/header.jpeg')"}}>
                    <span id="audio-icon" className="audio-icon" style={{backgroundImage: "url('/img/home/wave.gif')", backgroundPosition: "center", backgroundSize: "cover"}}>
                    </span>
                    <a href="/" className="mr-auto logo navbar navbar-expand-lg navbar-light d-flex flex-row-reverse" style={{paddingLeft: "15px", paddingBottom: "0px"}}>
                        <h1 className="margin-bottom: -10px;">
                            <img src="/img/home/logo.png" alt="stacktrace" width="40" height="50" style={{paddingBottom: "8px"}}/>
                            <span> School.StackTrace.In </span>
                        </h1>
                    </a>
                </span>
            </div>
		)
	}
}

export default Header;