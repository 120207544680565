'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';
import randomColor from "randomcolor";

class KindergartenEnglishGame extends React.Component {

	constructor(props) {
		super(props);
		this.state = {words:[],index:0,word:"",sel:[],opt:[],count:0,loop:0};
		this.checkWord = this.checkWord.bind(this);
		this.nextWord = this.nextWord.bind(this);
	}

	componentDidMount() {
	    $('#img-game').hide();
        $('#options').hide();
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/english-word-game"
        }).done(response => {
            this.setState({
                words: response.entity.words,
                word:  response.entity.words[this.state.index]
            });
            this.nextWord(response.entity.words[this.state.index]);
        }, response => {
        });
    }

    nextWord(w) {
        scroll_img_game();
        document.getElementById("page-"+this.state.word).style.background = "white";
        this.state.loop = this.state.loop + 1;
        this.state.word = w;
        this.state.count = 0;
        this.state.index = this.state.words.findIndex(el => el === w);
        document.getElementById("page-"+this.state.word).style.background = "yellow";

        $('#img-game').hide(500);
        $('#options').hide(500);
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/english-word-game?word="+w
        }).done(response => {
            this.setState({
                sel: response.entity.sel,
                opt: response.entity.opt
            });
            $('#img-game').show(1000);
            $('#options').show(1000);
        }, response => {
        });
    }

    checkWord(e,w) {
        scroll_img_game();
        if(this.state.sel.includes(w)) {
            this.state.count = this.state.count + 1;
            $('#'+this.state.loop+'sel-'+w).show(500);
            $('#'+this.state.loop+'opt-'+w).hide(500);

            if(this.state.count === this.state.sel.length) {
                $('#winImg').show(1000);
                $('#winImg').hide(2000);
                document.getElementById("winGameAudio").play();
                setTimeout(function() {
                    this.nextWord(this.state.words[(this.state.index === (this.state.words.length-1)) ? 0 : this.state.index+1]);
                }.bind(this), 2000);
            } else {
                $('#correctImg').show(500);
                $('#correctImg').hide(1000);
                document.getElementById("correctAnswerAudio").play();
            }
        } else {
            $('#wrongImg').show(500);
            $('#wrongImg').hide(1000);
            document.getElementById("wrongAnswerAudio").play();
        }
    }

	render() {
		return (
		    <div><Helmet><title>English Word Game - Kindergarten | School.StackTrace.In</title></Helmet>
		    <Container fluid className="card-body p-2">

                <Card className="lightbox card border-1 img-shadow p-1 m-1">
                    <div className="imageBox" style={{zIndex: 20}}>
                        <div className="images" style={{position: "relative", left: 0, top: 0}}>
                            <Card id="img-game" className="border-warning">
                                <h2 className="p-2 m-0 text-success" style={{textAlign: "center"}}>Select the '<b style={{color: "blue"}}>{this.state.word}</b>' words. </h2>
                            </Card>
                        </div>

                        <hr className="bg-success mb-0"/>
                        <div className="row justify-content-center">
                            { this.state.sel && this.state.sel.map(n =>
                                <button key={this.state.loop+"sel-"+n} id={this.state.loop+"sel-"+n} className="btn p-0 m-0" style={{width: "80px", fontSize: "30px", backgroundColor: "white", display: "none"}}>
                                {n}
                                </button>
                            )}
                        </div>
                        <hr className="bg-success mt-0"/>

                        <div id="correctImg" className="correctImg" style={{display: "none"}}>
                            <img src="/img/app/correct.png" style={{width: "100%"}}/>
                        </div>
                        <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                            <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                        </div>
                        <div id="winImg" className="winImg" style={{display: "none"}}>
                            <img src="/img/app/win.png" style={{width: "100%"}}/>
                        </div>
                        <CONST.ResultAudio correctAnswerAudio={true} wrongAnswerAudio={true} winGameAudio={true}/>
                    </div>

                    <div id="options" className="row justify-content-center">
                        { this.state.opt && this.state.opt.map(n =>
                            <button key={this.state.loop+"opt-"+n} id={this.state.loop+"opt-"+n} className="btn border-info img-shadow p-0 m-2" style={{width: "100px", fontSize: "40px", backgroundColor: randomColor({luminosity: 'light',format: 'hsla'})}}
                                onClick={(e) => { this.checkWord(e, n) }}> {n}
                            </button>
                        )}
                    </div>
                </Card>

                <br/>
                <fieldset className="row border m-2">
                    <legend className ='text-center' style={{width: "70px"}}>Page</legend>
                        <div className="text-center p-1">
                            { this.state.words && this.state.words.map(n =>
                                <button key={"page-"+n} id={"page-"+n} className="btn-circle btn border-danger p-0 m-1" style={{fontSize: "25px"}}
                                    onClick={(e) => { this.nextWord(n) }} > {n}
                                </button>
                            )}
                        </div>
                 </fieldset>

			</Container>
			</div>
		)
	}
}

export default KindergartenEnglishGame;