'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';

class NurseryBasicShapes extends React.Component {

	constructor(props) {
		super(props);
		this.state = {n1:[]};
	}

	componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/nursery/shapes"
        }).done(response => {
            this.setState({
                n1: response.entity.n1
            });
        }, response => {
        });
    }

	render() {

		return (
		    <div><Helmet><title>Basic Shapes - Nursery | School.StackTrace.In</title></Helmet>
		    <Container fluid className="card-body">
               <div className="row justify-content-center">
                    { this.state.n1 && this.state.n1.map(n =>
                        <CONST.ImageAudio key={n} id={n} ImgSrc={"/img/nursery/shapes/"+n+".png"} ImgTitle={n} ImgWidth="130" ImgHeight="130" AudioSrc={"/audio/nursery/shapes/"+n+".mp3"}/>
                    )}
                </div>
			</Container>
			</div>
		)
	}
}

export default NurseryBasicShapes;