'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';

class KindergartenMakeEnglishWord extends React.Component {

	constructor(props) {
        super(props);
        this.state = {english:[],game:[],word:"",char:[],pages:[],page:0,wordCount:0,charCount:0};
        this.makeWord = this.makeWord.bind(this);
        this.nextWord = this.nextWord.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/make-english-word?page=0"
        }).done(response => {
            this.setState({
                english: response.entity.english,
                game: response.entity.game,
                pages: response.entity.pages
            });
            this.nextWord(response.entity.game[this.state.wordCount]);
        }, response => {
        });
    }

    nextWord(game) {
        $("#img-game0").attr('src', "");
        this.setState({
            word: game.word,
            char: game.char
        });
        document.getElementById("page-"+this.state.page).style.background = "yellow";
    }

    makeWord(w) {
        scroll_img_game();
        if(this.state.char[this.state.charCount] == w) {
            $('#'+this.state.page+""+this.state.wordCount+""+this.state.charCount+'char_'+w).show(500);
            this.state.charCount = this.state.charCount + 1;

            if(this.state.charCount === this.state.char.length) {
                $('#winImg').show(1000);
                $('#winImg').hide(2000);
                document.getElementById("doneGreatAudio").play();
                setTimeout(function() {
                    this.state.charCount = 0;
                    if(this.state.wordCount === (this.state.game.length-1)) {
                        this.nextPage((this.state.page == (this.state.pages.length-1)) ? 0 : this.state.page+1);
                    } else {
                        this.state.wordCount = this.state.wordCount+1;
                        this.nextWord(this.state.game[this.state.wordCount]);
                    }
                }.bind(this), 2000);
            } else {
                $('#correctImg').show(500);
                $('#correctImg').hide(1000);
                document.getElementById("correctAudio").play();
            }
        } else {
            $('#wrongImg').show(500);
            $('#wrongImg').hide(1000);
            document.getElementById("wrongAudio").play();
        }
    }

    nextPage(p) {
        scroll_img_game();
        document.getElementById("page-"+this.state.page).style.background = "white";
        this.state.page = p;
        if(this.state.page === this.state.pages.length) {
            this.state.page = 0;
        }
        document.getElementById("page-"+p).style.background = "yellow";
        this.state.wordCount = 0;
        this.state.charCount = 0;
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/make-english-word?page="+this.state.page
        }).done(response => {
            this.setState({
                english: response.entity.english,
                game: response.entity.game,
                pages: response.entity.pages
            });
            this.nextWord(response.entity.game[this.state.wordCount]);
        }, response => {
        });
    }

    render() {
        return (
            <div><Helmet><title>Make English Word - Kindergarten | School.StackTrace.In</title></Helmet>
            <Container fluid className="card-body">

                <h5>Make english word of the object.</h5>
                <hr className="bg-warning"/>

                <div className="imageBox" style={{zIndex: 20}}>
                    <div className="images" style={{position: "relative", left: 0, top: 0}}>
                        <img id="img-game" src="/img/app/blank.png" className="game-image border img-shadow img-responsive" style={{position: "relative", left: 0, top: 0}}/>
                        <img id="img-game0" src={this.state.word ?("/img/kindergarten/english/"+this.state.word+".png"):""} className="game-image img-responsive p-2" style={{position: "absolute", left: 0, top: 0}}/>
                    </div>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <div id="winImg" className="winImg" style={{display: "none"}}>
                        <img src="/img/app/win.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAudio={true} wrongAudio={true} doneGreatAudio={true}/>
                </div>

                <hr className="bg-success mb-0"/>
                <div className="row justify-content-center">
                    { this.state.char && this.state.char.map((n,i) =>
                        <button key={this.state.page+""+this.state.wordCount+""+i+"char_"+n} id={this.state.page+""+this.state.wordCount+""+i+"char_"+n}
                            className="btn p-0 m-0 col-2" style={{width: "80px", fontSize: "30px", backgroundColor: "white", display: "none"}}>
                        {n.toUpperCase()}
                        </button>
                    )}
                </div>
                <hr className="bg-success mt-0"/>

                <div className="row justify-content-center">
                    { this.state.english && this.state.english.map(n =>
                        <CONST.ImageAudio key={n} id={n} ImgSrc={"/img/kindergarten/english/"+n+".png"} ImgWidth="80" ImgHeight="80" AudioSrc={"/audio/nursery/english/"+n+".mp3"} ClickAction={this.makeWord}/>
                    )}
                </div>


                <br/>
                <fieldset className="row border m-2">
                    <legend className ='text-center m-0' style={{width: "70px"}}>Level</legend>
                        <div className="text-center p-1">
                            { this.state.pages && this.state.pages.map((n,i) =>
                                <button key={"page-"+i} id={"page-"+i} className="btn-circle btn border-danger img-shadow p-0 m-1" style={{fontSize: "20px",width: "110px"}}
                                    onClick={(e) => { this.nextPage(i) }} > {n}
                                </button>
                            )}
                        </div>
                 </fieldset>

            </Container>
            </div>
        )
    }
}

export default KindergartenMakeEnglishWord;