'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';

class NurseryShapesGame extends React.Component {

	constructor(props) {
		super(props);
		this.state = {n1:[],g1:[],index:0,game:[]};
		this.clickAction = this.clickAction.bind(this)
	}

	componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/nursery/shapes-game"
        }).done(response => {
            this.setState({
                n1: response.entity.n1,
                g1: response.entity.g1,
                game: response.entity.g1[0].split('-')
            });
        }, response => {
        });
    }

    clickAction(n) {
        scroll_img_game();
        if(n===this.state.game[0]) {
            $('#correctImg').show(500);
            $('#correctImg').hide(2000);
            document.getElementById("correctAnswerAudio").play();

            this.state.index=this.state.index + 1;
            if(this.state.index === this.state.g1.length) {
                this.state.index=0;
            }
            this.state.game=this.state.g1[this.state.index].split('-');
            var gameImg = this.state.game[1];
             $("#img-game0").fadeOut(1000, function () {
                $("#img-game0").attr('src', "");
                $("#img-game0").attr('src', "/img/nursery/shapes/"+gameImg+".png");
                $("#img-game0").fadeIn(2000);
            });
        } else {
            $('#wrongImg').show(500);
            $('#wrongImg').hide(2000);
            document.getElementById("wrongAnswerAudio").play();
        }
    }

	render() {
		return (
		    <div><Helmet><title>Shapes Game - Nursery | School.StackTrace.In</title></Helmet>
		    <Container fluid className="card-body">

                <h5>Identify the shape of the object ?</h5>
                <hr className="bg-warning"/>

                <div className="imageBox" style={{zIndex: 20}}>
                    <div className="images" style={{position: "relative", left: 0, top: 0}}>
                        <img id="img-game" src="/img/app/blank.png" className="game-image border img-shadow img-responsive" style={{position: "relative", left: 0, top: 0}}/>
                        <img id="img-game0" src={this.state.game && this.state.game.length?("/img/nursery/shapes/"+this.state.game[1]+".png"):""} className="game-image img-responsive p-2" style={{position: "absolute", left: 0, top: 0}}/>
                    </div>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAnswerAudio={true} wrongAnswerAudio={true}/>
                </div>

                <hr className="bg-success"/>

                <div className="row justify-content-center">
                    { this.state.n1 && this.state.n1.map(n =>
                        <CONST.ImageAudio key={n} id={n} ImgSrc={"/img/nursery/shapes/"+n+".png"} ImgTitle={n} ImgWidth="80" ImgHeight="80" AudioSrc={"/audio/nursery/shapes/"+n+".mp3"} ClickAction={this.clickAction}/>
                    )}
                </div>

			</Container>
			</div>
		)
	}
}

export default NurseryShapesGame;