export const appURL  = 'http://school.stacktrace.in';

export {default as App}                         from "../app/app";
export {default as Header}                      from "../app/header";
export {default as Home}                        from "../app/home";

export {default as ResultAudio}                 from "../common/_01_Audio";
export {default as ImageAudio}                  from "../common/_02_ImageAudio";
export {default as PageAudio}                   from "../common/_03_PageAudio";

export {default as NurseryApp}                  from "../nursery/_01_app";
export {default as NurseryLeftBar}              from '../nursery/_02_leftBar';
export {default as NurseryHome}                 from "../nursery/_03_home";
export {default as NurseryBasicMathematics}     from "../nursery/_11_BasicMathematics";
export {default as NurseryMathematicsGame}      from "../nursery/_12_MathematicsGame";
export {default as NurseryBasicEnglish}         from "../nursery/_21_BasicEnglish";
export {default as NurseryEnglishGame}          from "../nursery/_22_EnglishGame";
export {default as NurseryEnglishWriting}       from "../nursery/_23_EnglishWriting";
export {default as NurseryHindiAAhh}            from "../nursery/_31_HindiAAhh";
export {default as NurseryHindiKGya}            from "../nursery/_32_HindiKGya";
export {default as NurseryBasicShapes}          from "../nursery/_41_BasicShapes";
export {default as NurseryShapesGame}           from "../nursery/_42_ShapesGame";
export {default as NurseryBasicColors}          from "../nursery/_51_BasicColors";
export {default as NurseryColorsGame}           from "../nursery/_52_ColorsGame";
export {default as NurseryFruits}               from "../nursery/_61_Fruits";
export {default as NurseryVegetables}           from "../nursery/_62_Vegetables";
export {default as NurseryAnimals}              from "../nursery/_63_Animals";
export {default as NurserySeparateFruitsVegetables}              from "../nursery/_71_SeparateFruitsVegetables";


export {default as KindergartenApp}                         from "../kindergarten/_01_app";
export {default as KindergartenLeftBar}                     from '../kindergarten/_02_leftBar';
export {default as KindergartenHome}                        from "../kindergarten/_03_home";
export {default as KindergartenEnglishGame}                 from "../kindergarten/_13_EnglishGame";
export {default as KindergartenVowelsGame}                  from "../kindergarten/_14_VowelsGame";
export {default as KindergartenEnglishWordGame}             from "../kindergarten/_15_EnglishWordGame";
export {default as KindergartenMakeEnglishWord}             from "../kindergarten/_16_MakeEnglishWord";
export {default as KindergartenFindWord}                    from "../kindergarten/_17_FindWord";
export {default as KindergartenNumberName}                  from "../kindergarten/_21_NumberName";
export {default as KindergartenMathOperation}               from "../kindergarten/_22_MathOperation";
export {default as KindergartenMyBody}                      from "../kindergarten/_31_MyBody";
