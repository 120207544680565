'use strict';

import * as CONST from "../app/constants";
export const React = require('react');
export const ReactDOM = require('react-dom');
import ReactGA from 'react-ga';
const TRACKING_ID = "G-S7CPMGP5GF";
ReactGA.initialize(TRACKING_ID);

const { BrowserRouter, Routes, Route} = require('react-router-dom');


class AppRoute extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
		    <BrowserRouter>
                <Routes>

                    <Route path="/" element={<CONST.App content={<CONST.Home/>} title="SchoolStackTrace"/>}/>

                    <Route path="/nursery"                                  element={<CONST.NurseryApp content={<CONST.NurseryHome/>}                               title="Nursery Class"/>}/>
                    <Route path="/nursery-math"                             element={<CONST.NurseryApp content={<CONST.NurseryBasicMathematics/>}                   title="Mathematics Class"/>}/>
                    <Route path="/nursery-math-game"                        element={<CONST.NurseryApp content={<CONST.NurseryMathematicsGame/>}                    title="Mathematics Game"/>}/>
                    <Route path="/nursery-english"                          element={<CONST.NurseryApp content={<CONST.NurseryBasicEnglish/>}                       title="English Class"/>}/>
                    <Route path="/nursery-english-game"                     element={<CONST.NurseryApp content={<CONST.NurseryEnglishGame/>}                        title="English Game"/>}/>
                    <Route path="/nursery-english-writing"                  element={<CONST.NurseryApp content={<CONST.NurseryEnglishWriting/>}                     title="English Writing"/>}/>
                    <Route path="/nursery-hindi-a-ahh"                      element={<CONST.NurseryApp content={<CONST.NurseryHindiAAhh/>}                          title="Hindi A-Ahh Class"/>}/>
                    <Route path="/nursery-hindi-k-gya"                      element={<CONST.NurseryApp content={<CONST.NurseryHindiKGya/>}                          title="Hindi K-Gya Class"/>}/>
                    <Route path="/nursery-shapes"                           element={<CONST.NurseryApp content={<CONST.NurseryBasicShapes/>}                        title="Shapes Class"/>}/>
                    <Route path="/nursery-shapes-game"                      element={<CONST.NurseryApp content={<CONST.NurseryShapesGame/>}                         title="Shapes Game"/>}/>
                    <Route path="/nursery-colors"                           element={<CONST.NurseryApp content={<CONST.NurseryBasicColors/>}                        title="Colors Class"/>}/>
                    <Route path="/nursery-colors-game"                      element={<CONST.NurseryApp content={<CONST.NurseryColorsGame/>}                         title="Colors Game"/>}/>
                    <Route path="/nursery-fruits"                           element={<CONST.NurseryApp content={<CONST.NurseryFruits/>}                             title="Fruits Chapter"/>}/>
                    <Route path="/nursery-vegetables"                       element={<CONST.NurseryApp content={<CONST.NurseryVegetables/>}                         title="Vegetables Chapter"/>}/>
                    <Route path="/nursery-animals"                          element={<CONST.NurseryApp content={<CONST.NurseryAnimals/>}                            title="Animals Chapter"/>}/>
                    <Route path="/nursery-separate-fruits-vegetables"       element={<CONST.NurseryApp content={<CONST.NurserySeparateFruitsVegetables/>}           title="Separate Fruits Vegetables Game"/>}/>


                    <Route path="/kindergarten"                             element={<CONST.KindergartenApp content={<CONST.KindergartenHome/>}                     title="Kindergarten Class"/>}/>
                    <Route path="/kindergarten-english-game"                element={<CONST.KindergartenApp content={<CONST.KindergartenEnglishGame/>}              title="English Game"/>}/>
                    <Route path="/kindergarten-vowels-game"                 element={<CONST.KindergartenApp content={<CONST.KindergartenVowelsGame/>}               title="Vowels Game"/>}/>
                    <Route path="/kindergarten-english-word-game"           element={<CONST.KindergartenApp content={<CONST.KindergartenEnglishWordGame/>}          title="English Word Game"/>}/>
                    <Route path="/kindergarten-make-english-word"           element={<CONST.KindergartenApp content={<CONST.KindergartenMakeEnglishWord/>}          title="Make English Word"/>}/>
                    <Route path="/kindergarten-find-word"                   element={<CONST.KindergartenApp content={<CONST.KindergartenFindWord/>}                 title="Find Word"/>}/>
                    <Route path="/kindergarten-number-name"                 element={<CONST.KindergartenApp content={<CONST.KindergartenNumberName/>}               title="Number Name"/>}/>
                    <Route path="/kindergarten-math-operation"              element={<CONST.KindergartenApp content={<CONST.KindergartenMathOperation/>}            title="Math Operation"/>}/>
                    <Route path="/kindergarten-my-body"                     element={<CONST.KindergartenApp content={<CONST.KindergartenMyBody/>}                   title="My Body"/>}/>

                </Routes>
            </BrowserRouter>
		)
	}
}

ReactDOM.render(<AppRoute/>,document.getElementById('react'));

