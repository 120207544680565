'use strict';

import * as CONST from "../app/constants";

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class KindergartenMatchEnglishWord extends React.Component {

    constructor(props) {
        super(props);
        this.state = {divHeight:"500", game: {}};
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/nursery/separate-fruits-vegetables"
        }).done(response => {
            this.setState({
                game: response.entity
            });
        }, response => {
        });
    }


    onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {


                const sourceColumn = this.state.game[source.droppableId];
                const destColumn = this.state.game[destination.droppableId];
                const sourceItems = [...sourceColumn.items];
                const destItems = [...destColumn.items];
                const [removed] = sourceItems.splice(source.index, 1);
                destItems.splice(destination.index, 0, removed);
                this.state.game[source.droppableId].items = sourceItems;
                this.state.game[destination.droppableId].items = destItems;
                if(document.getElementById(destination.droppableId).clientHeight > document.getElementById(source.droppableId).clientHeight) {
                    this.setState({ divHeight: document.getElementById(destination.droppableId).clientHeight });
                } else {
                    this.setState({ divHeight: document.getElementById(source.droppableId).clientHeight });
                }

                var flag = true;
                destItems.forEach(function (item, index) {
                  if(destination.droppableId != item.id.substring(0, 10).replaceAll("_", "")) {
                    flag = false;
                  }
                });
                sourceItems.forEach(function (item, index) {
                  if(source.droppableId != item.id.substring(0, 10).replaceAll("_", "")) {
                    flag = false;
                  }
                });

                if(flag) {
                    $('#winImg').show(1000);
                    $('#winImg').hide(1000);
                    document.getElementById("winGameAudio").play();
                    scroll_img_game();
                    setTimeout(function() {
                        this.state.game = {};
                        this.componentDidMount();
                    }.bind(this), 2000);

                } else {
                    if((destItems.at(destination.index).id.substring(0, 10).replaceAll("_", "")) === (destination.droppableId)) {
                        $('#correctImg').show(500);
                        $('#correctImg').hide(500);
                        document.getElementById("correctAudio").play();
                    } else {
                        $('#wrongImg').show(500);
                        $('#wrongImg').hide(500);
                        document.getElementById("wrongAudio").play();
                    }
                }

        } else {
                const column = this.state.game[source.droppableId];
                const copiedItems = [...column.items];
                const [removed] = copiedItems.splice(source.index, 1);
                copiedItems.splice(destination.index, 0, removed);
                this.state.game[source.droppableId].items = copiedItems;
        }
    }

    render() {
        return (
            <div><Helmet><title>Separate Fruits And Vegetables - Nursery | School.StackTrace.In</title></Helmet>
            <Container fluid className="card-body" id="img-game">
                <h5>Drag Drop and Separate.</h5>
                <hr className="bg-warning"/>

                {this.state.game && this.state.game.fruits &&
                    <div style={{ display: "flex", justifyContent: "center", height: "100%" }} className="row">
                        <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
                            <ItemBox key={"fruits"} columnId={"fruits"} column={this.state.game.fruits} name="Fruits" divHeight={this.state.divHeight} color1={"rgba(255, 0, 0, 0.2)"} color2={"rgba(255, 0, 0, 0.5)"} />
                            <ItemBox key={"vegetables"} columnId={"vegetables"} column={this.state.game.vegetables} name="Vegetables" divHeight={this.state.divHeight} color1={"rgba(0, 255, 0, 0.2)"} color2={"rgba(0, 255, 0, 0.5)"} />
                        </DragDropContext>
                    </div>
                }

                <div style={{position: "fixed", top: "10%"}}>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <div id="winImg" className="winImg" style={{display: "none"}}>
                        <img src="/img/app/win.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAudio={true} wrongAudio={true} winGameAudio={true}/>
                </div>

            </Container>
            </div>
        );
    }
}



class ItemBox extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
		    <div className="col-6 p-1">
		    <h1 className="text-center">{this.props.name}</h1>
            <div className="card m-0">
                <Droppable droppableId={this.props.columnId}>
                    {(provided, snapshot) => {
                        return (
                            <div {...provided.droppableProps} ref={provided.innerRef}
                                style={{background: snapshot.isDraggingOver ? this.props.color2 : this.props.color1, padding: 4, minHeight: this.props.divHeight}}
                            ><div id={this.props.columnId}>
                                {this.props.column.items.map((item, index) => {
                                    return (
                                        <div key={item.id+""+index} className="m-1">
                                        <Draggable draggableId={item.id} index={index}>
                                            {(provided, snapshot) => { return ( <ItemBoxImage provided={provided} snapshot={snapshot} item={item}/> ); }}
                                        </Draggable>
                                        </div>
                                    );
                                })}
                                {provided.placeholder}
                            </div></div>
                        );
                    }}
                </Droppable>
            </div>
            </div>
		)
	}
}

class ItemBoxImage extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
            <div ref={this.props.provided.innerRef} {...this.props.provided.draggableProps} {...this.props.provided.dragHandleProps}
                style={{...this.props.provided.draggableProps.style}} >
                <CONST.ImageAudio key={this.props.item.name} id={this.props.item.name}
                    ImgSrc={"/img/nursery/"+this.props.item.id.substring(0, 10).replaceAll("_", "")+"/"+this.props.item.name+".png"}
                    ImgTitle={this.props.item.name}
                    ImgWidth="130" ImgHeight="130"
                    AudioSrc={"/audio/nursery/"+this.props.item.id.substring(0, 10).replaceAll("_", "")+"/"+this.props.item.name+".mp3"}
                    BackgroundColor={this.props.snapshot.isDragging ? "yellow" : "rgb(250, 255, 255)"}
                />
            </div>
		)
	}
}


export default KindergartenMatchEnglishWord;
