'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

class NumberName extends React.Component {

	constructor(props) {
		super(props);
		this.state = {n1:[],n2:[],n3:[]};
	}

	componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/nursery/math"
        }).done(response => {
            this.setState({
                n1: response.entity.n1,
                n2: response.entity.n2,
                n3: response.entity.n3,
            });
        }, response => {
        });
    }

	render() {

		return (
		    <div><Helmet><title>Number Name - Kindergarten | School.StackTrace.In</title></Helmet>
		    <Container fluid className="card-body">

                <div>
                    { this.state.n1 && this.state.n1.map(n =>
                        <Row key={n}>
                            <Col><CONST.ImageAudio id={n+"_number"} ImgSrc={"/img/nursery/math/"+n+".png"} ImgWidth="80" ImgHeight="80" AudioSrc={"/audio/nursery/math/"+n+".mp3"}/></Col>
                            <Col><CONST.ImageAudio id={n+"_name"} ImgSrc={"/img/kindergarten/number/"+n+".png"} ImgWidth="130" ImgHeight="80" AudioSrc={"/audio/nursery/math/"+n+".mp3"}/></Col>
                        </Row>
                    )}
                </div>

                <hr className="bg-success"/>

                <div>
                    { this.state.n2 && this.state.n2.map(n =>
                        <Row key={n}>
                            <Col><CONST.ImageAudio id={n+"_number"} ImgSrc={"/img/nursery/math/"+n+".png"} ImgWidth="80" ImgHeight="80" AudioSrc={"/audio/nursery/math/"+n+".mp3"}/></Col>
                            <Col><CONST.ImageAudio id={n+"_name"} ImgSrc={"/img/kindergarten/number/"+n+".png"} ImgWidth="130" ImgHeight="80" AudioSrc={"/audio/nursery/math/"+n+".mp3"}/></Col>
                        </Row>
                    )}
                </div>

			</Container>
			</div>
		)
	}
}

export default NumberName;