'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
const { Link } = require('react-router-dom');

class KindergartenLeftBar extends React.Component {

    constructor(props) {
		super(props);
    }

	render() {
		return (
			<div style={{width:"100%"}}>
                <Link to="/kindergarten" className="list-group-item list-group-item-action list-group-item-warning font-weight-bold text-danger"><h5 className="m-0"> Kindergarten Class </h5></Link>
                <Link to="/kindergarten-english-game" className="list-group-item list-group-item-action text-primary">English Game</Link>
                <Link to="/kindergarten-vowels-game" className="list-group-item list-group-item-action text-primary">Vowels Game</Link>
                <Link to="/kindergarten-english-word-game" className="list-group-item list-group-item-action text-primary">English Word Game</Link>
                <Link to="/kindergarten-make-english-word" className="list-group-item list-group-item-action text-primary">Make English Word</Link>
                <Link to="/kindergarten-find-word" className="list-group-item list-group-item-action text-primary">Find Word</Link>
                <Link to="/kindergarten-number-name" className="list-group-item list-group-item-action text-primary">Number Name</Link>
                <Link to="/kindergarten-math-operation" className="list-group-item list-group-item-action text-primary">Math Operation</Link>
                <Link to="/kindergarten-my-body" className="list-group-item list-group-item-action text-primary">My Body</Link>
            </div>
		)
	}
}

export default KindergartenLeftBar;