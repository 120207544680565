'use strict';

import React from 'react'
import ReactDOM from 'react-dom';

class PageAudio extends React.Component {

	constructor(props) {
		super(props);
		this.clickAudio = this.clickAudio.bind(this)
        this.audioOnEnded = this.audioOnEnded.bind(this)
	}

	clickAudio(e,n) {
        $("#audio-icon").fadeIn();
        document.getElementById("audio_"+n).play();
    }

    audioOnEnded(e,n){
        $("#audio-icon").fadeOut();
    }

	render() {
	    const { id, src } = this.props;
		return (
            <audio id={"audio_"+id} src={src} onEnded={(e) => { this.audioOnEnded(e, id); }}/>
		)
	}
}

export default PageAudio;