'use strict';

import * as CONST from "../app/constants";

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import ImageMapper from 'react-image-mapper';

class KindergartenMyBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = { n:[], n1:[], word:[], find:[], findNum:0, choose:"",pages:[],page:0 };
        this.clicked = this.clicked.bind(this);
        this.validateWord = this.validateWord.bind(this);
        this.validate = this.validate.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/find-word?page=0"
        }).done(response => {
            this.setState({
                n: response.entity.n,
                n1: response.entity.n1,
                word: response.entity.word,
                find: response.entity.find,
                pages: response.entity.pages
            });
        }, response => {
        });
    }

    clicked(e,n,i) {
        document.getElementById("audio_"+n).play();
        var txt = ",|"+i+"|";
        if(this.state.choose.includes(txt)) {
            document.getElementById("w"+i).classList.remove("box-color-yellow");
            this.state.choose = this.state.choose.replace(txt,'');
        } else {
            document.getElementById("w"+i).classList.add("box-color-yellow");
            this.state.choose = this.state.choose+txt;
        }

        if(this.validateWord()) {

            var cWord = this.state.choose;
            cWord.split(",").forEach(function (cItem) {
                if(cItem.length > 0) {
                    document.getElementById(""+cItem).classList.add("box-event-none");
                }
            });

            this.state.choose = "";
            this.state.findNum = this.state.findNum + 1;

            setTimeout(function() {
                if(this.state.findNum == this.state.word.length) {
                    $('#winImg').show(500);
                    $('#winImg').hide(2000);
                    document.getElementById("winGameAudio").play();
                    setTimeout(function() {
                        this.nextPage((this.state.page == (this.state.pages.length-1)) ? 0 : this.state.page+1);
                    }.bind(this), 3000);
                } else {
                    $('#correctImg').show(500);
                    $('#correctImg').hide(1000);
                    document.getElementById("correctAudio").play();
                }
            }.bind(this), 500);
        }
    }

    validateWord() {
        var flag = false;
        var cWord = this.state.choose;
        var gameWordLength = this.state.page + 3;
        var chooseWordLength = cWord.split(",").length;

        console.log("cWord: "+cWord);
        console.log("gameWordLength: "+gameWordLength+" | chooseWordLength: "+chooseWordLength);

        if(gameWordLength == chooseWordLength) {
            flag = this.validate();

            if(!flag) {
                setTimeout(function() {
                    $('#wrongImg').show(500);
                    $('#wrongImg').hide(1000);
                    document.getElementById("wrongAudio").play();

                    cWord.split(",").forEach(function (cItem) {
                        if(cItem.length > 0) {
                            document.getElementById("w"+(cItem.split("|"))[1]).classList.remove("box-color-yellow");
                        }
                    });
                }.bind(this), 500);

                this.setState({ choose: "" });
            }
        }

        console.log("validateWordLength: "+flag);
        return flag;
    }

    validate() {
        var flag = false;
        var cWord = this.state.choose;
        var findItem = "";

        this.state.find.forEach(function (fItem) {
            if(!flag) {
                flag = true;
                cWord.split(",").forEach(function (cItem) {
                    if(flag && !fItem.includes(cItem)) {
                        flag = false;
                    }
                });
                if(flag) {
                    findItem = fItem;
                }
            }
        });

        if(flag) {
            document.getElementById("word_"+this.state.find.indexOf(findItem)).classList.add("box-color-yellow");
        }
        return flag;
    }

    nextPage(p) {
        scroll_img_game();
        document.getElementById("page-"+this.state.page).style.background = "white";
        this.state.page = p;
        if(this.state.page === this.state.pages.length) {
            this.state.page = 0;
        }
        document.getElementById("page-"+p).style.background = "yellow";
        this.state.wordCount = 0;
        this.state.charCount = 0;

        var i = 0
        this.state.n1.forEach(function (n) {
            document.getElementById("w"+i).classList.remove("box-color-yellow");
            document.getElementById("|"+i+"|").classList.remove("box-event-none");
            i++;
        });
        client({
            method: "GET",
            path: CONST.appURL+"/kindergarten/find-word?page="+this.state.page
        }).done(response => {
            this.setState({
                n: response.entity.n,
                n1: response.entity.n1,
                word: response.entity.word,
                find: response.entity.find,
                pages: response.entity.pages,
                findNum: 0,
                choose: ""
            });
        }, response => {
        });


    }


    render() {
        return (
            <div><Helmet><title>Find Word - Kindergarten | School.StackTrace.In</title></Helmet>
            <Container fluid className="card-body" style={{minHeight: "700px"}}>
                <h5>Find for the words give below and select them.</h5>

                <hr id="img-game" className="card bg-warning mb-2"/>
                { this.state.word && this.state.word.map((w,index) =>
                    <span key={"key_"+w}>
                        <button id={"word_"+index} className="m-1 border-warning btn btn-light"> {w} </button>
                    </span>
                )}
                <hr className="card bg-warning mt-2"/>

                { this.state.n && this.state.n.map(n =>
                    <CONST.PageAudio key={n} id={n} src={"/audio/nursery/english/"+n+".mp3"}/>
                )}


                <div className="game-image">
                    <div className="row justify-content-center">
                        { this.state.n1 && this.state.n1.map((n,i) =>
                            <a key={n+""+i} id={"|"+i+"|"} className="col-2 p-0" onClick={(e) => this.clicked(e, n.split('-')[0], i)}>
                                <img id={"w"+i} className={"btn-block btn border-secondary p-2 m-0 box-color-"+n.split('-')[1]}
                                src={"/img/nursery/english/small/"+n.split('-')[0]+".png"} width="80%" />
                            </a>
                        )}
                    </div>
                </div>


                <div style={{position: "fixed", top: "10%", zIndex: "10"}}>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <div id="winImg" className="winImg" style={{display: "none"}}>
                        <img src="/img/app/win.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAudio={true} wrongAudio={true} winGameAudio={true} />
                </div>


                <br/>
                <br/>
                <fieldset className="row border">
                    <legend className ='text-center m-0' style={{width: "70px"}}>Level</legend>
                    <div className="text-center p-1">
                        { this.state.pages && this.state.pages.map((n,i) =>
                            <button key={"page-"+i} id={"page-"+i} className="btn-circle btn border-danger img-shadow p-0 m-1"
                                style={{fontSize: "20px",width: "110px", background: (this.state.page == i ? "yellow": "")}}
                                onClick={(e) => { this.nextPage(i) }} > {n}
                            </button>
                        )}
                    </div>
                </fieldset>

            </Container>
            </div>
        );
    }
}

export default KindergartenMyBody;

