'use strict';

import * as CONST from "../app/constants";
const React = require('react');
const ReactDOM = require('react-dom');

const { Card, CardBody, Container, Row } = require('reactstrap');
const { Link } = require('react-router-dom');

class Home extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
		    <section className="gallery-block cards-gallery mx-auto text-center p-0 pl-1 pr-1">
                <div className="row">
                    <div className="col-6 col-md-4 col-lg-3 p-1">
                        <div className="card border-0 transform-on-hover mb-0 p-2">
                            <Link className="lightbox" to="/nursery">
                                <img className="card-img-top" src="/img/home/nursery.png" alt="nursery"/>
                                <div className="h5 pt-2 p-0 m-0"> Nursery </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 p-1">
                        <div className="card border-0 transform-on-hover mb-0 p-2">
                            <Link className="lightbox" to="/kindergarten">
                                <img className="card-img-top" src="/img/home/kindergarten.png" alt="kindergarten"/>
                                <div className="h5 pt-2 p-0 m-0"> Kindergarten </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
		)
	}
}

export default Home;