'use strict';

import * as CONST from "../app/constants";

import React from 'react'
import ReactDOM from 'react-dom';
import client from '../config/client';
import { Helmet } from 'react-helmet';
import { Card, CardBody, Container, Row } from 'reactstrap';
import CanvasDraw from "react-canvas-draw";
import randomColor from "randomcolor";

class NurseryEnglishWriting extends React.Component {

	constructor(props) {
		super(props);
		this.state = {n1:[], index:0,
        		    writing: "A", result: "", flag: true,
        		    color: "blue",
                    width: 300,
                    height: 400,
                    brushRadius: 15,
                    lazyRadius: 0
                };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateWriting = this.validateWriting.bind(this);
	}

	componentDidMount() {
        client({
            method: "GET",
            path: CONST.appURL+"/nursery/english"
        }).done(response => {
            this.setState({
                n1: response.entity.n1
            });
        }, response => {
        });
    }

    handleSubmit(e, data) {
        document.getElementById("clickAudio").play();
        const payload = {};
        payload['data'] = data;
        client({
            method: 'POST',
            path: CONST.appURL+"/nursery/english-writing",
            entity: payload,
            headers: {'Content-Type': 'application/json',}
        }).done(response => {
            this.state.result= response.entity;
            this.validateWriting();
        }, response => {
            this.state.result= response.entity;
            this.validateWriting();
        });
    }

    validateWriting() {
        scroll_img_game();
        this.setState({ flag: true });

        var w = isNaN(this.state.writing * 1) ? (this.state.writing).trim().toUpperCase() : this.state.writing;
        var r = isNaN(this.state.result * 1) ? (this.state.result).trim().toUpperCase() : this.state.result;

        if(w == r) {
            $('#correctImg').show(500);
            $('#correctImg').hide(2000);
            document.getElementById("correctAudio").play();

            this.state.index=this.state.index + 1;
            if(this.state.index === this.state.n1.length) {
                this.state.index=0;
            }
            this.setState({ writing: (this.state.n1[this.state.index]).trim().toUpperCase() });

            setTimeout(()=> {
                this.saveableCanvas.eraseAll();
                document.getElementById("audio1").play();
            },1000);

          this.setState({
            color: randomColor({luminosity: 'bright', format: 'rgb' })
          });

        } else {
            this.setState({ flag: false });
            $('#wrongImg').show(500);
            $('#wrongImg').hide(2000);
            document.getElementById("wrongAudio").play();
        }
    }

	render() {

		return (
		    <div><Helmet><title>English Writing - Nursery | School.StackTrace.In</title></Helmet>
		    <Container fluid className="card-body">

                <div className="imageBox" style={{zIndex: 20}}>
                    <div className="images" style={{position: "relative", left: 0, top: 0}}>
                        <Card id="img-game" className="border-0"><CardBody className="bg-light p-0 mx-auto border img-shadow">
                            <h2 style={{textAlign: "center"}}>Please Write: <b>{this.state.writing}</b></h2>
                            <CanvasDraw
                                ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                                brushColor={this.state.color}
                                brushRadius={this.state.brushRadius}
                                lazyRadius={this.state.lazyRadius}
                                canvasWidth={this.state.width}
                                canvasHeight={this.state.height}
                            />
                        </CardBody></Card>
                    </div>
                    <div id="correctImg" className="correctImg" style={{display: "none"}}>
                        <img src="/img/app/correct.png" style={{width: "100%"}}/>
                    </div>
                    <div id="wrongImg" className="wrongImg" style={{display: "none"}}>
                        <img src="/img/app/wrong.png" style={{width: "100%"}}/>
                    </div>
                    <CONST.ResultAudio correctAudio={true} wrongAudio={true} clickAudio={true} audio1Src="/audio/nursery/write/write.mp3"/>
                </div>
                <br/>
                <div>
                    <button className="btn btn-success img-shadow btn-lg m-1" onClick={(e) => { document.getElementById("clickAudio").play(); this.handleSubmit(e, this.saveableCanvas.getDataURL()); }}> Validate </button>
                    <button className="btn btn-danger img-shadow btn-lg m-1 float-right" onClick={() => { document.getElementById("clickAudio").play(); this.saveableCanvas.eraseAll();}}> Erase </button>
                </div>

                { this.state.flag ? "" : <>
                    <hr className="bg-success"/>
                    <h4>Your Writing Look Like: {this.state.result}</h4>
                    <hr className="bg-success"/></>
                }
                <br/>

			</Container>
			</div>
		)
	}
}

export default NurseryEnglishWriting;