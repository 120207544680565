'use strict';

import React from 'react'
import ReactDOM from 'react-dom';

class ImageAudio extends React.Component {

	constructor(props) {
		super(props);
		this.clickAudio = this.clickAudio.bind(this)
        this.audioOnEnded = this.audioOnEnded.bind(this)
        this.gameAction = this.gameAction.bind(this)
	}

	clickAudio(e,n) {
        $("#audio-icon").fadeIn();
        document.getElementById("img_"+n).style.background = 'yellow';
        document.getElementById("audio_"+n).play();
    }

    audioOnEnded(e,n){
        $("#audio-icon").fadeOut();
        document.getElementById("img_"+n).style.background = 'rgb(250, 255, 255)';
        this.props.ClickAction ? this.props.ClickAction(n) : "";
    }

    gameAction(e,n){
        this.props.GameAction ? this.props.GameAction(n) : "";
    }

	render() {
	    const { id, ImgSrc, ImgWidth, ImgHeight, ImgTitle, AudioSrc, GameAction, BackgroundColor } = this.props;
		return (
            <>
                { ImgTitle ?
                        <span id={"img_"+id} className="img-card card img-shadow btn mx-auto mb-2 align-items-center d-flex justify-content-center"
                            style={{position: "unset",backgroundColor: BackgroundColor ? BackgroundColor : "rgb(250, 255, 255)"}}
                            onClick={(e) => { AudioSrc ? this.clickAudio(e, id) : "" }}>
                                <img src={ImgSrc} width={ImgWidth} height={ImgHeight}/>
                                <p className="p-0 m-0" style={{textAlign: "center"}}>{ImgTitle}</p>
                        </span>
                    :   <img id={"img_"+id} className="img-btn btn success border img-shadow p-2 m-2"
                           src={ImgSrc} width={ImgWidth} height={ImgHeight}
                           onClick={(e) => { AudioSrc ? this.clickAudio(e, id) : <>{ GameAction ? this.gameAction(e, id) : "" }</> }}
                        />
                    }

                { AudioSrc ?
                    <audio id={"audio_"+id} src={AudioSrc} onEnded={(e) => { this.audioOnEnded(e, id); }}/>
                    :
                    ""
                }
            </>
		)
	}
}

export default ImageAudio;